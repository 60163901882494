/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Attachment } from '../models/attachment';
import { Page } from '../models/page';
import { SortOrder } from '../models/sort-order';
import { UpdateAttachmentDto } from '../models/update-attachment-dto';
import { UploadAttachmentDto } from '../models/upload-attachment-dto';

@Injectable({ providedIn: 'root' })
export class PropertyAttachmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listAttachments()` */
  static readonly ListAttachmentsPath = '/property-attachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAttachments$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Attachment>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, PropertyAttachmentService.ListAttachmentsPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Attachment>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAttachments(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Attachment>;
}> {
    return this.listAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Attachment>;
}>): Page & {
'result': Array<Attachment>;
} => r.body)
    );
  }

  /** Path part for operation `uploadAttachment()` */
  static readonly UploadAttachmentPath = '/property-attachment';

  /**
   * Only supports files with mimetype `application/pdf` and `image/*`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAttachment$Response(
    params: {
      body: UploadAttachmentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PropertyAttachmentService.UploadAttachmentPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `application/pdf` and `image/*`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAttachment(
    params: {
      body: UploadAttachmentDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.uploadAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteAttachments()` */
  static readonly DeleteAttachmentsPath = '/property-attachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachments$Response(
    params: {
      attachmentIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PropertyAttachmentService.DeleteAttachmentsPath, 'delete');
    if (params) {
      rb.query('attachmentIds', params.attachmentIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachments(
    params: {
      attachmentIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateAttachment()` */
  static readonly UpdateAttachmentPath = '/property-attachment/{attachmentId}';

  /**
   * Only supports files with mimetype `application/pdf` and `image/*`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateAttachment$Response(
    params: {
      attachmentId: string;
      body: UpdateAttachmentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PropertyAttachmentService.UpdateAttachmentPath, 'put');
    if (params) {
      rb.path('attachmentId', params.attachmentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `application/pdf` and `image/*`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateAttachment(
    params: {
      attachmentId: string;
      body: UpdateAttachmentDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
